// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore"
const firebaseConfig = {
  //karthi
  // apiKey: "AIzaSyDh8H_dmba6kzfQOSqlp6t9BnG7CTDhpHw",
  // authDomain: "dellvideoportalcloudstore.firebaseapp.com",
  // databaseURL: "https://dellvideoportalcloudstore-default-rtdb.firebaseio.com",
  // projectId: "dellvideoportalcloudstore",
  // storageBucket: "dellvideoportalcloudstore.appspot.com",
  // messagingSenderId: "184170202542",
  // appId: "1:184170202542:web:8537b554addf95aa69343f"
  
  //Myself
  // apiKey: "AIzaSyAfjqSOCBFkKcFIoaI5BZ4aV9Ta6vDKPE4",
  // authDomain: "dell-519f1.firebaseapp.com",
  // databaseURL: "https://dell-519f1-default-rtdb.firebaseio.com",
  // projectId: "dell-519f1",
  // storageBucket: "dell-519f1.appspot.com",
  // messagingSenderId: "13263881825",
  // appId: "1:13263881825:web:5014da78b0a3da254f8c20",
  // measurementId: "G-THLZFLQLXR"

  // production
  apiKey: "AIzaSyClk_Z1CW9om7zT3A175ztfpx_QuF-aPGQ",
  authDomain: "videoportal-903ed.firebaseapp.com",
  projectId: "videoportal-903ed",
  databaseURL: "https://videoportal-903ed-default-rtdb.firebaseio.com",
  storageBucket: "videoportal-903ed.appspot.com",
  messagingSenderId: "340557366428",
  appId: "1:340557366428:web:a362fc63722799ddd49959",
  measurementId: "G-NL6DBXPENV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db =getFirestore(app)

// const config = {
//   apiKey: "AIzaSyAfjqSOCBFkKcFIoaI5BZ4aV9Ta6vDKPE4",
//   authDomain: "dell-519f1.firebaseapp.com",
//   databaseURL: "https://react-firebase-basic.firebaseio.com"
// };

// export default config;